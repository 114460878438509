import { useState, useEffect } from 'react';
import { Form, Button } from "react-bootstrap";
import useProducts from "../hooks/useProducts";
import Testimony from './Testimony';
import { REACT_APP_BASE_URL } from '../config/config';
import { useAuthHeader } from "react-auth-kit";

const styles = {
    formContainer: { maxWidth: "700px", margin: "0 auto", padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "10px" },
    heading: { textAlign: "center", marginBottom: "30px" },
    priceTag: { textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#28a745" },
    description: { textAlign: "center", marginBottom: "20px" },
    buttonContainer: { display: "flex", justifyContent: "center" },
    cardContainer: { marginTop: "30px" }
};

function WhatsAppGroupDetails() {
    const authHeader = useAuthHeader();

    // Step 1: Create a state variable for the input
    const [inputValue, setInputValue] = useState('');
    const [price, setPrice] = useState(0.00);
    const { products } = useProducts(authHeader());
    const [product]  = useState("WhatsApp Group");


    useEffect(() => {
        if (products.length > 0) {
          const myProduct = products.find(my_product => my_product.name === product);
          if (myProduct) {
            setPrice(myProduct.price);
          }
        }
    }, [products, product]);

    // Step 2: Handle the input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Step 3: Generate the URL dynamically
    const whatsappUrl = `${REACT_APP_BASE_URL}/client/subscribe/WhatsApp-Group/${inputValue ? inputValue : ''}`;

    return (
        <div style={styles.formContainer}>
            <h1 style={styles.heading}>Join Our Exclusive WhatsApp Group</h1>
            <h2 style={styles.priceTag}>Only R{price}</h2>
            <p style={styles.description}>
                Connect with like-minded individuals, receive personalized support, and gain access to exclusive content and updates. Don't miss out on this opportunity to be part of our community!
            </p>
            
            {/* Step 4: Input field for the optional value */}
            <Form.Group className="mb-3">
                <Form.Label>Coupon (Optional)</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="" 
                    value={inputValue} 
                    onChange={handleInputChange} 
                />
            </Form.Group>

            {/* Step 5: Button to join WhatsApp group, with the dynamic URL */}
            <div style={styles.buttonContainer}>
                <Button
                    href={whatsappUrl}
                    variant="success"
                >
                    Join WhatsApp Group
                </Button>
            </div>

            {/* Testimony section */}
            <div style={styles.cardContainer}>
                <Testimony slidesToShow={2} />
            </div>
        </div>
    );
}

export default WhatsAppGroupDetails;
